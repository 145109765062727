import React from 'react';
/*
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as weatherActions } from '../../store/WeatherStore';
*/
import './WeatherItem.css';

export const WeatherItem = (props) => {
  const { Temperature, WindSpeed, CloudCover, Precipitation } = props;

  const weatherCloudAndPrecipitation = (temp, cloud, precipitation) => {
    if (temp > 15 && cloud < 0.1) {
      return 'https://raw.githubusercontent.com/Makin-Things/weather-icons/master/animated/clear-day.svg'; // solrigt
    } else if (temp <= 12 && precipitation < 0.4) {
      return 'https://raw.githubusercontent.com/Makin-Things/weather-icons/master/animated/cloudy.svg'; // cloudy
    } else if (temp <= 12 && precipitation < 1) {
      return 'https://raw.githubusercontent.com/Makin-Things/weather-icons/master/animated/rainy-1-day.svg'; // lidt regn
    } else if (temp <= 15 && precipitation >= 1 && precipitation <= 3) {
      return 'https://raw.githubusercontent.com/Makin-Things/weather-icons/master/animated/rainy-2-day.svg'; // nogen regn
    } else if (temp <= 15 && precipitation >= 3 && precipitation < 10) {
      return 'https://raw.githubusercontent.com/Makin-Things/weather-icons/master/animated/rainy-3-day.svg'; // moderat regn
    } else if (temp <= 8 && precipitation >= 10 && precipitation <= 24) {
      return 'https://raw.githubusercontent.com/Makin-Things/weather-icons/master/animated/rainy-3.svg'; // en del regn
    } else if (temp <= 8 && precipitation >= 24) {
      return 'https://raw.githubusercontent.com/Makin-Things/weather-icons/master/animated/cloudy-3-day.svg'; // 8°C kraftig regn
    } else if (temp <= 0 && precipitation > 1) {
      return 'https://raw.githubusercontent.com/Makin-Things/weather-icons/master/animated/rain-and-snow-mix.svg'; // sne og regn
    } else {
      return null
    }
  }



  const windSpeed = (windTempSpeed) => {
    if (windTempSpeed <= 5.4) {
      return 'https://raw.githubusercontent.com/Makin-Things/weather-icons/master/animated/wind.svg'
    }
    else if (windTempSpeed >= 5.4 && windTempSpeed <= 10.7) {
      return 'https://raw.githubusercontent.com/Makin-Things/weather-icons/master/animated/wind.svg'
    }
    else if (windTempSpeed >= 10.7 && windTempSpeed <= 20.7) {
      return 'https://raw.githubusercontent.com/Makin-Things/weather-icons/master/animated/wind.svg'
    }
    else if (windTempSpeed >= 20.8) {
      return 'https://raw.githubusercontent.com/Makin-Things/weather-icons/master/animated/wind.svg'

    } else {
      return null; // Standardværdi, hvis ingen af betingelserne er opfyldt
    }

  }

  /* const precipitation = (Precipitation) => {
     if (Precipitation <= 1) {
       return 'qi-305' //lidt og let regn
     }
     else if (Precipitation >= 1 && Precipitation <= 3) {
       return 'qi-306' //nogen regn
     }
     else if (Precipitation >= 3 && Precipitation <= 10) {
       return 'qi-307' // regn/moderat regn
     }
     else if (Precipitation >= 10 && Precipitation <= 24) {
       return 'qi-308' // en del regn
     }
     else if (Precipitation >= 24) {
       return 'qi-309' //kraftig regn
     }
 
   }*/


  return (
    <div className='weather-container'>
      <div className={"weather"}>

        <div className={"icon"}>
          <div className='valueTemperatur'>
            {typeof Temperature === 'number' ? Temperature.toFixed(0) + '°C' : ''}
          </div>
          <div className="weatherCloudAndPrecipitation-icon-container">

            {/* <img src={weatherCloudAndPrecipitation(Temperature, CloudCover, Precipitation)} alt=''></img> */}
            <img src={weatherCloudAndPrecipitation(Temperature, CloudCover, Precipitation)} alt='' className="weatherCloudAndPrecipitation-icon" />

          </div>
          <div className='valuePrecipitation'>
            {/* {typeof Precipitation === 'number' ? Precipitation.toFixed(1) + 'mm' : '...'} */}
            {typeof Precipitation === 'number' ? (Precipitation < 0.1 ? '0 mm' : Precipitation.toFixed(1) + ' mm') : ''}
          </div>
        </div>


        <div className={"icon"}>
          <div className='value'>
            {typeof WindSpeed === 'number' ? WindSpeed.toFixed(1) + 'm/s' : ''}
          </div>

          <div className="wind-icon-container">
            {/* <img src={windSpeed(WindSpeed)} alt=''></img> */}
            <img src={windSpeed(WindSpeed)} alt='' className="wind-icon" />
            {/* <img src={windSpeed(WindSpeed)} alt='' style={{ width: '60px', height: '60px' }} /> */}
          </div>

        </div>
      </div>
    </div>
  );


}      