import React from 'react';
import Clock from 'react-live-clock';
import { actionCreators as routerActions } from '../store/RouterActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actionCreators as storyActions } from '../store/StoryStore';



//import mapImage from '../images/map.png';
import logoImage from '../images/customer_logo.png';

import './Menu.css';

const Menu = (props) => {

    const deviceState = props.deviceState;
    return (
        <div className="menu">
            <Clock format={'HH:mm'} ticking={true} timezone={'Europe/Copenhagen'} interval={30000} />
            <div className="menu-point-container">
                <div className="menu-point">
                    <div onClick={() => props.routerActions.navigate('/')}>
                        <img src={require('../images/safety.png')} alt='Hjem menu' />
                    </div>
                </div>


                {
                    deviceState.devices.find(group => group.group === 'AVV' && group.deviceIds.includes(Number(localStorage.getItem('deviceId')))) ?
                        <div className="menu-point">
                            <div onClick={() => props.routerActions.navigate('/extrainteractivestory')}>
                                <img src={require('../images/tænk_miljø_helt_hvid.png')} alt='Poster menu' />
                            </div>
                        </div> : <></>
                }

                <div className="menu-point">
                    <div onClick={() => props.routerActions.navigate('/documents')}>
                        <img src={require('../images/dokument_ikon.png')} alt='Arkiv menu' />
                    </div>
                </div>
                <div className="menu-point">
                    <div onClick={() => props.routerActions.navigate('/instructions')}>
                        <img src={require('../images/instruktions_video_mappe_ikon.png')} alt='Video arkiv menu' />
                    </div>
                </div>
                {/* Hidden miljøplakat knap for alle andre værker */}
                <div className="menu-point">
                    <div onClick={() => props.routerActions.navigate('/extrainteractivestory')}>
                        <img className='hidden' src={require('../images/tænk_miljø_helt_hvid.png')} alt='Poster menu' />
                    </div>
                </div>

                {/* 
                <div className="menu-point" onClick={() => props.routerActions.navigate('/map')}>
                    <img src={mapImage} alt='Map menu' />
                </div>
                */}
                <img style={{ position: 'absolute', bottom: 0, left: 0, opacity: 1 }} src={logoImage} alt='' />
            </div>

        </div>
    );
};

export default connect(
    (state) => ({
        deviceState: state.deviceState
    }),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
        storyActions: bindActionCreators(storyActions, dispatch),


    })

)(Menu);